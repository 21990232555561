import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../SEWA/components/Sidebar2024';
import { Archive2022 } from '../ScintillaOfArt/components';

const OrphanageVisit2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/7.webp`;
  const p8 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/8.webp`;
  const p9 = `${config.base_image_url}/home/events-activities/sewa/OrphanageVisit2024/9.webp`;
 
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 2,
    },
    {
      src: p4,
      source: p4,
      rows: 1.5,
      cols: 1,
    },
    {
        src: p5,
        source:p5,
        rows: 1.5,
        cols: 1,
      },
      {
        src: p6,
        source:p6,
        rows: 1.5,
        cols: 1,
      },
      {
        src: p7,
        source:p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source:p8,
        rows: 1,
        cols: 2,
      },
      {
        src: p9,
        source:p9,
        rows: 1.2,
        cols: 1.5,
      },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Srirampura orphanage homes 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 9	  &  Date: 16 NOVEMBER 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        On Saturday, 16th November 2024, students of Class 9 visited the Ashoka Pillar and Srirampura orphanage homes as part of the school’s community outreach programme. The visit was an enriching and heart warming experience for our students. It aimed at spreading smiles on faces of less-privileged children and instilling the values of compassion, empathy and social responsibility in students.
        <br></br>
        During the visit, the students spent quality time with the children. They distributed snacks, sports equipments, handmade pen stands, pouches and note books. They also engaged them with various games and fun activities. The highlight of the day was a captivating performance by the orphanage children, showcasing their incredible talent and creativity.
        <br></br>
        The visit had made our students empathetic and compassionate for the under-privileged and the needy. They realised that true happiness result from what we give and not from what we get. The visit was a true embodiment of the school’s mission to nurture compassion and social responsibility among students.
        <br></br>
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Climate change is the Everest of all problems, the thorniest challenge facing humankind - Lewis Gordon Pugh”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2024/> 
             </Box>
             <Box marginBottom={4}>
               <Archive2022/> 
             </Box>
             
    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default OrphanageVisit2024;